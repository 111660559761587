import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../css/hero.css';
import profileImage from '../assets/potrait.jpeg'; // Replace with the correct path

const HeroSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Array of blog data
    const blogData = [
      { date: 'January 17, 2022', title: 'Getting Started with Git', link: '/blogs/getting-started-with-git' },
    ];

    // Array of project data
    const projectData = [
      { date: 'September 29, 2024', title: 'Breast Cancer Classification', link: '/projects/bcc' },
    ];

    // Select the top 3 blogs
    setBlogs(blogData.slice(0, 3));
    // Select all projects
    setProjects(projectData);
  }, []);

  return (
    <>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <img src={profileImage} alt="Profile" className="profile-img" />
          <h1 className="hero-title">
            <span>Hi, I'm Dev ✌️</span>
          </h1>
          <h2>
            <span>Building with Data,</span> <br />
            <span> transforming raw data into actionable insights</span>
          </h2>
          <p>
          <span>"Data, in its rawest form, is a story waiting to be told. My role is to uncover it, turning insights into guidance."</span>
          </p>
          <button
            className="connect-btn"
            onClick={() =>
              window.open('https://www.linkedin.com/in/devsendatham/', '_blank')
            }
          >
            Connect with me
          </button>
        </div>
      </section>

      <section className="mouse">
      <div className="scroll-downs">
  <div className="mousey">
    <div className="scroller"></div>
  </div>
</div>
      </section>

      {/* Main Section */}
      <section className="main-section">
        {/* Latest Blogs Section */}
        <div className="latest-blogs">
          <h2>
          <Link to="/blogs" style={{ color: 'inherit', textDecoration: 'none' }}>
              Blogs <span></span>
            </Link>
          </h2>
          <p className="description">
            I occasionally write about programming, productivity, and more.
          </p>

          <div className="blog-list">
            {blogs.map((blog, index) => (
              <div className="blog-item" key={index}>
                <span className="blog-date">{blog.date}</span>
                <Link to={blog.link} className="blog-title">
                  {blog.title}
                </Link>
              </div>
            ))}
          </div>
        </div>

        {/* Projects Section */}
        <div className="projects-section">
          <h2>
            <Link to="/projects" style={{ color: 'inherit', textDecoration: 'none' }}>
              Projects
            </Link>
          </h2>
          <p className="description">
            A collection of projects I've worked on, showcasing my skills and interests.
          </p>

          <div className="project-list">
            {projects.map((project, index) => (
              <div className="project-item" key={index}>
                <span className="project-date">{project.date}</span>
                <Link to={project.link} className="project-title">
                  {project.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
