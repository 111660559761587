import React, { useEffect } from 'react';
import '../css/about.css'; // Link to the About page styles
import '../css/navigation.css'; // Link to the Navigation styles

const About = () => {
  useEffect(() => {
    const setTimeGreeting = () => {
      const greetingElement = document.getElementById('time-greeting');
      const hours = new Date().getHours();
      let greeting;

      if (hours >= 5 && hours < 12) {
        greeting = 'Hello, early bird!';
      } else if (hours >= 12 && hours < 17) {
        greeting = 'Hello, Good afternoon';
      } else if (hours >= 17 && hours < 21) {
        greeting = 'Hello, Good evening!';
      } else {
        greeting = 'Hello, Night Owl!';
      }

      if (greetingElement) {
        greetingElement.style.color = '#daa520';
        greetingElement.textContent = greeting;
      }
    };

    setTimeGreeting();
  }, []);

  const showModal = (id) => {
    const modal = document.getElementById(id);
    if (modal) {
      modal.style.display = 'block';
    } else {
      console.error(`Modal with id "${id}" not found.`);
    }
  };
  
  const closeModal = (id) => {
    const modal = document.getElementById(id);
    if (modal) {
      modal.style.display = 'none';
    } else {
      console.error(`Modal with id "${id}" not found.`);
    }
  };
  
  

  return (
    <div className="about-page">
      {/* About Section */}
      <div className="about-section">
        <div className="content-wrapper">
          <div className="left-column">
            <h1>
              About Me<span className="color"> :</span>
            </h1>
            <div className="about-text">
              <p>
                <span id="time-greeting"></span>, I'm Dev! Originally from India,
                I'm now based in the vibrant city of Chicago.
              </p>
              <p>
                I’m a Data Scientist at Pixquid with a Master’s in Computer
                Science, specializing in Data Science, Full Stack Development,
                and Machine Learning.
              </p>
              <p>
                Outside of work, I’m passionate about photography, exploring new
                cuisines, and discovering unique spots around the city. When I’m
                not diving into data or behind the lens, you can find me gaming,
                connecting with friends, or adding to my collection of Hot
                Wheels.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Work Experience Timeline */}
      <div className="section timeline">
        <h4 className="timeline-title">
          Work<span className="color"> :</span>
        </h4>
        <div className="timeline-container">
          <div className="timeline-block" onClick={() => showModal('modal1')}>
            <div className="timeline-logo">
              <img src="/pixquid.jpg" alt="Pixquid Logo" />
            </div>
            <div className="timeline-content">
              <h4>Data Scientist</h4>
              <p>Pixquid</p>
            </div>
            <span className="timeline-date">Aug 2024 - Present</span>
          </div>

          <div className="timeline-block" onClick={() => showModal('modal2')}>
            <div className="timeline-logo">
              <img src="/vistex.jpeg" alt="Vistex Logo" />
            </div>
            <div className="timeline-content">
              <h4>Data Science Intern</h4>
              <p>Vistex</p>
            </div>
            <span className="timeline-date">Sept 2023 - Dec 2023</span>
          </div>

          <div className="timeline-block" onClick={() => showModal('modal3')}>
            <div className="timeline-logo">
              <img src="/istc.jpg" alt="ISTC Logo" />
            </div>
            <div className="timeline-content">
              <h4>Data Science Intern</h4>
              <p>ISTC</p>
            </div>
            <span className="timeline-date">June 2023 - Aug 2023</span>
          </div>

          <div className="timeline-block" onClick={() => showModal('modal4')}>
            <div className="timeline-logo">
              <img src="/vuedata_logo.jpeg" alt="Vuedata Logo" />
            </div>
            <div className="timeline-content">
              <h4>Software Engineer</h4>
              <p>Vuedata</p>
            </div>
            <span className="timeline-date">Jun 2020 - Aug 2021</span>
          </div>
        </div>
      </div>

      {/* Modals */}
      <div id="modal1" className="modal">
        <div className="modal-content">
          <button className="close" onClick={() => closeModal('modal1')}>
            &times;
          </button>
          <h2>Pixquid</h2>
          <h3>Data Scientist</h3>
          <p>Chicago, IL &nbsp;&nbsp;&nbsp;&nbsp; August 2024 – Present</p>
          <ul>
          <li>Developed and deployed the Pixquid platform on Bubble, integrating Stripe for payments and AWS S3 for media management. Implemented IAM roles for secure access and configured CORS for cross-origin media delivery.</li>
       <li>Built a custom upload tool for videos/images, enabling direct uploads from Bubble to AWS S3. Leveraged S3 for scalable storage, CloudFront for fast global delivery, and Lambda for serverless backend tasks.</li>
       <li>Created custom JavaScript plugins and designed data analytics strategies to optimize processing and efficiency. Utilized AWS EC2 for scalable compute and S3 for secure storage</li>
          </ul>
        </div>
      </div>

      <div id="modal2" className="modal">
        <div className="modal-content">
          <button className="close" onClick={() => closeModal('modal2')}>
            &times;
          </button>
          <h2>Vistex</h2>
          <h3>Data Science Intern</h3>
          <p>Chicago, IL &nbsp;&nbsp;&nbsp;&nbsp; Sept 2023 – Dec 2023</p>
          <ul>
          <li>Improved data visualization by optimizing Python-driven line graphs and heat maps, reducing shareholder meeting time by 25% (from 60 to 45 minutes) and boosting decision-making efficiency.</li>
       <li>Led a causal impact analysis in Python to assess marketing campaign effectiveness, improving campaign efficiency by 15% through pre- and post-campaign performance comparison, adjusting for external factors.</li>
       <li>Increased sales forecasting accuracy by 20% by implementing ARIMA models in Python, facilitating better financial planning and resource allocation.</li>
          </ul>
        </div>
      </div>

      <div id="modal3" className="modal">
        <div className="modal-content">
          <button className="close" onClick={() => closeModal('modal3')}>
            &times;
          </button>
          <h2>Illinois Science and tech Coalition</h2>
          <h3>Data Science Intern</h3>
          <p>Chicago, IL &nbsp;&nbsp;&nbsp;&nbsp;  June 2023 – Sept 2023</p>
          <ul>
          <li>Streamlined data searches across U.S. educational databases with a custom Python and PowerBI tool, cutting processing times by 30% and boosting productivity by 20% through automation.</li>
       <li>Improved data quality by 15% by enhancing validation and cleaning procedures in federal and local datasets to ensure accuracy and goal alignment.</li>
       <li>Increased sales forecasting accuracy by 20% by implementing ARIMA models in Python, facilitating better financial planning and resource allocation.</li>
          </ul>
        </div>
      </div>

      <div id="modal4" className="modal">
        <div className="modal-content">
          <button className="close" onClick={() => closeModal('modal4')}>
            &times;
          </button>
          <h2>Vuedata Technologies</h2>
          <h3>Software Engineer</h3>
          <p>Chennai, IN &nbsp;&nbsp;&nbsp;&nbsp; June 2020 – Aug 2021</p>
          <ul>
          <li>Launched a Cross-Platform Fitness Application for Android and iOS, collaborating with the UI/UX team to implement innovative designs and architecture.</li>
       <li>Integrated Firebase to enhance app functionality and used Git for version control, adopting Agile methodologies with Jira to manage the project lifecycle and Utilized Postman for API testing and Tableau  for generating data n reports from the application database.</li>
       <li>During the initial internship phase (June 2020 - August 2020), assisted senior developers in resolving critical bugs and UI issues, and gained practical experience in Android application development.</li>
          </ul>
        </div>
      </div>

      {/* Repeat for other modals */}
    </div>
  );
};

export default About;
