import React, { useState, useEffect } from 'react';
import '../css/Header.css';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [headerAnimated, setHeaderAnimated] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  useEffect(() => {
    // Trigger animation on location change
    setHeaderAnimated(true);
    const timer = setTimeout(() => setHeaderAnimated(false), 500); // Match animation duration
    return () => clearTimeout(timer); // Cleanup timer
  }, [location]);

  return (
    <header className={`header ${headerAnimated ? 'animated' : ''}`}>
      <div className="logo">
        <img src="/logo.svg" alt="logo" />
      </div>
      
      <div className={`menu ${menuActive ? 'active' : ''}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><a href="/resume">Resume</a></li>
          <li><Link to="/gallery">Gallery</Link></li>
        </ul>
      </div>
      <div className={`nav-icon ${menuActive ? 'active' : ''}`} onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </header>
  );
};

export default Header;
