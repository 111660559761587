import React from 'react';
import '../css/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
    <div className="footer-container">
      <div className="footer-social">
        <a href="https://linkedin.com/in/devsendatham" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://github.com/devsendatha" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="mailto:devsendatham@gmail.com" target="_blank" rel="noopener noreferrer">Email</a>
      </div>
      <div className="footer-name">Designed and Developed 👨‍💻 by Devsen Datha Mukkamala 🍺</div>
    </div>
  </footer>
  );
};

export default Footer;
