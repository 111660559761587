import React from 'react';
import '../css/resume.css';

const ResumePage = () => {
  return (
    <div className="resume-page">

      {/* About Section */}
      <header className="header-section">
        <h1>Devsen Datha Mukkamala</h1>
        <p>
          devsendatham@gmail.com | +1 (312) 581-7449 |{' '}
          <a href="https://www.linkedin.com/in/devsendatham" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </p>
        <div className="download-button-container">
  <a href="/Devsen%20Datha%20Mukkamala%20Resume.pdf" className="download-button" download>
    Download Resume
  </a>
</div>

      </header>

      {/* Professional Skills */}
      <section id="skills" className="section">
        <h2>Professional Skills and Interests</h2>
        <div className="skills-container">
          <div className="skills-category">
            <h3>Programming Languages & Libraries</h3>
            <p>Python, Pandas, NumPy, scikit-learn, TensorFlow, PyTorch, Keras</p>
          </div>
          <div className="skills-category">
            <h3>Full-Stack Development</h3>
            <p>React.js, Node.js, Express.js, HTML, CSS, JavaScript</p>
          </div>
          <div className="skills-category">
            <h3>Machine Learning Techniques</h3>
            <p>Decision Trees, Random Forest, SVM, Gradient Boosting</p>
          </div>
          <div className="skills-category">
            <h3>Data Visualization</h3>
            <p>Tableau, Matplotlib, Seaborn, Plotly, Power BI</p>
          </div>
          <div className="skills-category">
            <h3>Cloud & Database Management</h3>
            <p>AWS (EC2, S3, Lambda, RDS), MySQL, MongoDB, PostgreSQL</p>
          </div>
        </div>
      </section>

      {/* Experience Section */}
      <section id="experience" className="section">
        <h2>Professional Work Experience</h2>
        <div className="experience-item">
          <h3>Pixquid - Data Scientist</h3>
          <p>August 2024 – Present</p>
          <p>Developed and deployed a platform using Bubble, integrated Stripe for payments, and built custom plugins.</p>
        </div>
        <div className="experience-item">
          <h3>Vistex - Data Scientist Intern</h3>
          <p>September 2023 – December 2023</p>
          <p>Conducted causal analysis using Bayesian models, achieving a 15% improvement in campaign efficiency.</p>
        </div>
      </section>

      {/* Education Section */}
      <section id="education" className="section">
        <h2>Education</h2>
        <div className="education-item">
          <h3>DePaul University</h3>
          <p>Master of Science in Computer Science, June 2024</p>
        </div>
        <div className="education-item">
          <h3>Hindustan University</h3>
          <p>Bachelor of Technology in Computer Science, June 2020</p>
        </div>
      </section>

      {/* Projects Section */}
      <section id="projects" className="section">
        <h2>Academic Projects</h2>
        <div className="project-item">
          <h3>Predictive Modeling of Breast Cancer</h3>
          <p>Developed machine learning models with a recall score of 0.72 for the minority class using CNN.</p>
        </div>
      </section>

      {/* Achievements Section */}
      <section id="achievements" className="section">
        <h2>Leadership & Achievements</h2>
        <p>Awarded the Graduating Presidential Scholarship for six consecutive quarters.</p>
      </section>

      {/* Footer */}
      
    </div>
  );
};

export default ResumePage;
