import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Footer from './components/footer';
import About from './pages/about';
import Gallery from './pages/gallery';
import ResumePage from './pages/resume';
import ProgressBar from './components/navigation'; // Import ProgressBar
import BCC from './pages/projects/bcc'; // Import Breast Cancer Classification page
import './css/transitions.css'; // Add CSS for transitions
import './css/global.css'; // Global CSS
import GettingStartedWithGit from './pages/blogs/getting-started-with-git';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <ProgressBar /> {/* Add ProgressBar globally */}
        <Routes>
          {/* General Routes */}
          <Route path="/" element={<HeroSection />} /> {/* Home Route */}
          <Route path="/about" element={<About />} /> {/* About Route */}
          <Route path="/resume" element={<ResumePage />} /> {/* Resume Route */}
          <Route path="/gallery" element={<Gallery />} /> {/* Gallery Route */}
          
          {/* Project Routes */}
          <Route path="/projects/bcc" element={<BCC />} /> {/* Breast Cancer Classification */}

          {/* Blog Routes */}
          <Route path="/blogs/getting-started-with-git" element={<GettingStartedWithGit/>} /> {/* Breast Cancer Classification */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
