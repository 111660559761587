import React, { useEffect } from 'react';
import '../css/navigation.css'; // Link to the CSS file for progress bar

const ProgressBar = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      const progressFill = document.querySelector('.progressFill');
      if (progressFill) {
        progressFill.style.height = `${progress}%`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="progressContainer tilt-in-right-1">
      <div className="progressFill" id="myProgress"></div>
    </div>
  );
};

export default ProgressBar;
