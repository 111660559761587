import React from 'react';
import '../../css/projects.css'; // Adjust to your CSS path
import authorImage from '../../assets/potrait.jpeg'; // Path to your portrait image

const GettingStartedWithGit = () => {
  return (
    <div className="project-page">
      {/* Header Section */}
      <section className="project-header">
        <h1>Getting Started with Git</h1>
        <p>
          A beginner-friendly guide to understanding and using Git for version control. Learn how to track changes,
          collaborate effectively, and manage codebases like a pro.
        </p>
      </section>

      {/* Author Section */}
      <section className="project-author">
        <img
          src={authorImage}
          alt="Devsen Datha Mukkamala"
          className="author-image"
        />
        <div className="author-details">
          <h3>Devsen Datha Mukkamala</h3>
          <div className="project-info">
            <p>2024, Technical Guide</p>
            <a
              href="https://medium.com/@devsendatham/getting-started-with-git-cc63603f960a"
              className="visit-project-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read on Medium
            </a>
          </div>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="project-introduction">
        <h2>Introduction</h2>
        <p>
          Git is a powerful version control system that tracks changes in files and facilitates collaboration among
          developers. Whether you're working on a personal project or a team-based application, mastering Git is
          essential.
        </p>
      </section>

      {/* Key Concepts Section */}
      <section className="project-key-concepts">
        <h2>Key Concepts</h2>
        <ul>
          <li><strong>Repositories:</strong> A collection of files and their history managed by Git.</li>
          <li><strong>Commits:</strong> Snapshots of your code at a specific point in time.</li>
          <li><strong>Branches:</strong> Parallel versions of your codebase for experimentation and development.</li>
          <li><strong>Merge:</strong> Combining changes from different branches.</li>
        </ul>
      </section>

      {/* Git Commands Section */}
      <section className="project-commands">
        <h2>Essential Git Commands</h2>
        <ul>
          <li><strong>git init:</strong> Initialize a new Git repository.</li>
          <li><strong>git add:</strong> Stage changes for a commit.</li>
          <li><strong>git commit:</strong> Save your changes to the repository.</li>
          <li><strong>git branch:</strong> List, create, or delete branches.</li>
          <li><strong>git merge:</strong> Merge changes from one branch to another.</li>
          <li><strong>git push:</strong> Upload changes to a remote repository.</li>
          <li><strong>git pull:</strong> Fetch and merge changes from a remote repository.</li>
        </ul>
      </section>

      {/* Best Practices Section */}
      <section className="project-best-practices">
        <h2>Best Practices</h2>
        <p>
          To effectively use Git, consider the following best practices:
        </p>
        <ul>
          <li>Commit often with meaningful messages.</li>
          <li>Use branches for feature development and bug fixes.</li>
          <li>Regularly pull updates from the main branch to avoid conflicts.</li>
          <li>Review code before merging changes.</li>
        </ul>
      </section>

      {/* Conclusion Section */}
      <section className="project-conclusion">
        <h2>Conclusion</h2>
        <p>
          Git is an indispensable tool for modern software development. By mastering its commands and workflows,
          you can track changes, collaborate effectively, and enhance your productivity. Start exploring Git today
          to streamline your projects!
        </p>
      </section>
    </div>
  );
};

export default GettingStartedWithGit;
