import React from 'react';
import '../../css/projects.css';
import authorImage from '../../assets/potrait.jpeg'; // Path to your portrait image

const BreastCancerClassification = () => {
  return (
    <div className="project-page">
      {/* Header Section */}
      <section className="project-header">
        <h1>Breast Cancer Classification Using Machine Learning</h1>
        <p>
          This project explores the use of machine learning models for predicting whether a breast cancer tumor
          is malignant or benign. It leverages thorough data exploration, preprocessing, and advanced modeling
          techniques to improve performance and detection accuracy.
        </p>
      </section>

      {/* Author Section */}
      <section className="project-author">
        <img
          src={authorImage}
          alt="Devsen Datha Mukkamala"
          className="author-image"
        />
        <div className="author-details">
          <h3>Devsen Datha Mukkamala</h3>
          <div className="project-info">
            <p>2024, Data Science Project</p>
            <a
              href="https://github.com"
              className="visit-project-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Project
            </a>
          </div>
        </div>
      </section>

      {/* Dataset Section */}
      <section className="project-dataset">
        <h2>Dataset Information</h2>
        <p>
          The dataset, sourced from Kaggle, includes features such as tumor size, age, and other medical attributes.
          Key steps include:
        </p>
        <ul>
          <li>Data Cleaning: Minimal cleaning required as no missing values were present.</li>
          <li>Data Exploration: Identified key trends using descriptive statistics and visualizations.</li>
        </ul>
        <p><strong>Key Visualizations:</strong></p>
        <ul>
          <li><strong>2D Histogram:</strong> Age vs Tumor Size</li>
          <img
          src={require('../../assets/bcc/image.png')} // Replace with the correct image path
          alt="2D Histogram"
          className="visualization-image"/>
          <p className="image-text">
          In this step, we created a 2D histogram using Matplotlib to visualize the joint distribution of 'Age' and 'Tumor Size' in the
dataset. The plot shows how frequently different combinations of 'Age' and 'Tumor Size' occur, providing insights into
their distribution.The 2D histogram was generated using plt.hist2d with 20 bins for both 'Age' and 'Tumor Size', and the
'viridis' color map to represent frequency. Labels for the x-axis and y-axis were added, along with a plot title to indicate
the relationship being examined. A colorbar was included to help interpret the frequency values represented by different
colors.This visualization helps in understanding the density and distribution of age and tumor size combinations in the
dataset, highlighting areas with higher or lower frequencies.
          </p>
          <li><strong>Pie Chart:</strong> Tumor Stages</li>
          <img
          src={require('../../assets/bcc/image copy.png')} // Replace with the correct image path
          alt="2D Histogram"
          className="visualization-image"/>
          <li><strong>Bar Plot:</strong> Race Distribution</li>
          <img
          src={require('../../assets/bcc/image copy 2.png')} // Replace with the correct image path
          alt="2D Histogram"
          className="visualization-image"/>
          <li><strong>Count Plot:</strong> Tumor Size by Tumor Stage</li>
        </ul>
      </section>

      {/* Machine Learning Models Section */}
      <section className="project-models">
        <h2>Machine Learning Models</h2>
        <ul>
          <li><strong>Decision Tree Classifier:</strong> Accuracy ~79.5%, strong on majority class.</li>
          <li><strong>Random Forest Classifier:</strong> Accuracy ~87.6%, robust handling of class imbalance.</li>
          <li><strong>Support Vector Machine (SVM):</strong> Accuracy ~80.9%, balanced on majority and minority classes.</li>
          <li><strong>Gradient Boosting:</strong> Accuracy ~86.7%, excellent at class imbalance handling.</li>
          <li><strong>Logistic Regression:</strong> Accuracy ~79.8%, a good baseline model.</li>
        </ul>
      </section>

      {/* Hyperparameter Tuning Section */}
      <section className="project-hyperparameters">
        <h2>Hyperparameter Tuning</h2>
        <p>
          The models were tuned using techniques like grid search and Keras Tuner's Hyperband algorithm. SMOTE
          was used to address class imbalance, and early stopping improved generalization.
        </p>
      </section>

      {/* Conclusion Section */}
      <section className="project-conclusion">
        <h2>Conclusion</h2>
        <p>
          This project highlights the importance of thorough data exploration, visualization, and preprocessing.
          The final model achieved a validation accuracy of 88.9% and demonstrated robust handling of class imbalance.
        </p>
      </section>
    </div>
  );
};

export default BreastCancerClassification;
